import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Globals} from '../../../services/globals.service';
import {BaseComponent} from '../../../classes/base-component';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import {map, mergeMap, skip, switchMap} from 'rxjs/operators';
import {MetaResponse} from '../../../shared/interfaces/meta-response';
import {Observable, of, Subscription} from 'rxjs';
import * as moment from 'moment/moment';
import {HttpErrorResponse} from '@angular/common/http';
import {
    RealTimeAlertChartComponent
} from '../../../charts/real-time-alert-chart/real-time-alert-chart.component';
import {HomestateService} from '../../../services/homestate.service';
import {ApplicationService} from '../../../services/application.service';
import {ConfigurationService} from '../../../services/configuration.service';
import {AnimationOptions} from 'ngx-lottie';
import {RealTimeAlertService} from '../../../services/real-time-alert.service';

@Component({
    selector: 'app-real-time-alert-tile',
    templateUrl: './real-time-alert-tile.component.html',
    styleUrls: ['./real-time-alert-tile.component.scss'],
    providers: [Globals]
})
export class RealTimeAlertTileComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    private closeSubscription: Subscription;
    private readonly type: TILE_TYPE = TILE_TYPE.REAL_TIME_ALERT;
    private alertChart: RealTimeAlertChartComponent = null;
    private chartInitialized = false;
    private lastFetchedDataset: PowerValue[] = null;
    feedinDiagramSeriesColor = '#39393a';
    alarmsData = null;
    alarmsFromConfig = null;
    isLoading = true;
    showEmptyState = false;

    constructor(private tiles: TileService,
                private homestate: HomestateService,
                private application: ApplicationService,
                private configuration: ConfigurationService,
                private realTimeAlertService: RealTimeAlertService) {
        super();
    }

    lottieConfig: AnimationOptions = {
        path: 'assets/anim/empty-states/consumption-alert.json',
        renderer: 'svg',
        autoplay: true,
        loop: true,
        name: 'Echtzeitalarm '
    };


    ngOnInit() {
        this.fetchAlarmsData();

        this.closeSubscription = this.realTimeAlertService.closeEvent$.subscribe(() => {
            setTimeout(() => {
                this.fetchAlarmsData();
            }, 5000);
            this.updateChart();
        });
    }

    ngOnDestroy(): void {
        if (this.closeSubscription) {
            this.closeSubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        this.getSevenDayHistoricalData();
    }


    onTileClicked(): void {
        this.tiles.openDetailView(this.type);
    }

    fetchAlarmsData(): void {
        this.getAlarmsFromDashboardConfig().pipe(
            switchMap(() => this.getAlarmsData())
        ).subscribe({
            next: (mappedThresholds) => {
                this.alarmsData = mappedThresholds;
                this.showEmptyState = !this.alarmsData?.length;
                if (this.chartInitialized) {
                    this.addHorizontalLines();
                }
            },
            error: (err) => {
                this.isLoading = false;
                this.showEmptyState = true;
            }
        });
    }


    onChartLoaded(chart: RealTimeAlertChartComponent): void {
        this.alertChart = chart;
        this.chartInitialized = true;
        this.updateChart();
        if (this.alarmsData?.length) {
            this.addHorizontalLines();
        }
    }


    private getSevenDayHistoricalData(): void {
        this.realTimeAlertService.startRealTimeAlertValueLiveUpdate(6);
        this.realTimeAlertService.onRealTimeAlertDataUpdate.pipe(
            skip(1),
            mergeMap((results: MetaResponse) => {
                if (results.status === 'error') {
                    this.handleInstantaneousErrors(results.data);
                    return of(null);
                } else {
                    return of(results.data.results);
                }
            })
        ).subscribe({
            next: (result) => {
                try {
                    this.extractSeriesFromDataset(result);
                    if (this.chartInitialized) {
                        this.updateChart();
                    }
                    this.isLoading = false;
                    this.showEmptyState = false;
                } catch (error) {
                    if (this.chartInitialized) {
                        this.alertChart.showLoadingState();
                    }
                }
            }
        });
    }


    private getAlarmsFromDashboardConfig(): Observable<void> {
        if (this.application.isDemoMode()) {
            return of();
        }

        return this.configuration.requestAlarmsDataFromConfig().pipe(
            map((storedData) => {
                if (storedData.status === 'ok') {
                    this.alarmsFromConfig = storedData?.data?.power_thresholds;
                }
            })
        );
    }


    addHorizontalLines() {
        this.alertChart.resetHorizontalLines();

        this.alarmsData.forEach((data) => {
            if (data.enabled) {
                switch (data.type) {
                    case 'custom':
                        this.alertChart.addHorizontalLine(data.threshold_value, '#39393a', '/assets/img/graphics/real-time/service.png');
                        break;
                    case 'feed_in':
                        this.alertChart.addHorizontalLine(data.threshold_value, '#ffc300', '/assets/img/graphics/real-time/sun-inverted.png');
                        break;
                    case 'high':
                        this.alertChart.addHorizontalLine(data.threshold_value, '#b9280a', '/assets/img/graphics/real-time/high-consumption.png');
                        break;
                    case'medium':
                        this.alertChart.addHorizontalLine(data.threshold_value, '#eb4b0a', '/assets/img/graphics/real-time/mid-consumption.png');
                        break;
                    case 'low':
                        this.alertChart.addHorizontalLine(data.threshold_value, '#eb9674', '/assets/img/graphics/real-time/low-consumption.png');
                        break;
                    default:
                        this.alertChart.addHorizontalLine(data.threshold_value, '#39393a', '');
                }
            }
        });
    }

    private getAlarmsData(): Observable<any[]> {
        return this.homestate.fetchPowerThresholds().pipe(
            map(({data}) => {
                return Object.entries(data?.mains).map(([key, threshold]) => {
                    const configData = this.alarmsFromConfig[key];
                    return {
                        // @ts-ignore
                        ...threshold,
                        name: configData?.name || 'Unknown',
                        type: configData?.type || 'unknown',
                        key,
                    };
                });
            })
        );
    }


    private extractSeriesFromDataset(dataset: any): void {
        const start = moment().subtract(1, 'days').toDate();
        const end = moment().subtract(0, 'days').toDate();
        this.lastFetchedDataset = dataset.filter(el => {
            const ts = moment(el.timestamp).toDate();
            return ts >= start && ts <= end;
        });
    }


    private updateChart(): void {
        this.alertChart.showLoadingState(false);
        const fedEnergy = [];
        for (const el of this.lastFetchedDataset) {
            const element = {
                power: el.power < 0 ? Math.abs(el.power) : null,
                timestamp: el.timestamp
            };
            if (element.power) {
                fedEnergy.push(element);
            }
        }
        const consumedEnergy = [];
        for (const el of this.lastFetchedDataset) {
            const element = {
                power: el.power >= 0 ? el.power : null,
                timestamp: el.timestamp
            };
            consumedEnergy.push(element);
        }
        if (consumedEnergy.length) {
            this.alertChart.addNewSeries(
                consumedEnergy,
                'power',
                1,
            );
        }
        if (fedEnergy.length) {
            this.alertChart.addNewSeries(
                fedEnergy,
                'power',
                0,
                {zIndex: 2, isTileChart: true, color: this.feedinDiagramSeriesColor}
            );
        }
        const time = moment(this.lastFetchedDataset.first().timestamp).endOf('day');
        this.alertChart.insertVerticalLine(time.toDate());
    }

    private handleInstantaneousErrors(error: HttpErrorResponse): void {
        if (error.error.error.code === 290) {
            this.showEmptyState = true;
        }
        this.chartInitialized = false;
    }
}

export interface PowerValue {
    power: number;
    timestamp: Date;
}
